<template>
  <LayoutBox :loading="loading" title="操作违规统计" ref="box">
    <div ref="pieRef" style="height:100%"></div>
  </LayoutBox>
</template>

<script>
import LayoutBox from '@/views/screen/components/LayoutBox.vue';
export default {
  //组件注册
  components: { LayoutBox },
  //组件传值
  props: {
    prefix: {
      type: String,
      default: 'cash',
    },
    globalQuery: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      pieChart: null,
    };
  },
  //计算属性
  computed: {
    getOption() {
      return (data) => {
        return {
          tooltip: {
            trigger: 'item',
          },
          legend: {
            show: false,
          },

          series: [
            {
              type: 'pie',
              radius: '50%',
              data: data.map((e) => ({
                name: e.text,
                value: e.count,
              })),
              label: {
                lineHeight: 18,
                color: '#E9FAFF',
                formatter: '{d}%\n{b}',
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        };
      };
    },
  },
  //属性监听
  watch: {
    globalQuery: {
      deep: true,
      immediate: true,
      handler: 'getData',
    },
  },
  //DOM访问
  mounted() {



      this.$bus.$on(`warning-success-${this.prefix}`, this.getData);

  },
  //保存方法
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/${this.prefix}/warning-stat`,
          this.globalQuery
        );
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        this.$nextTick(()=>{
          this.pieChart = this.$echarts.init(this.$refs.pieRef);
          this.pieChart.setOption(this.getOption(data.data));
        })
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
